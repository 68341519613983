import { Controller } from '@hotwired/stimulus';
import { Fancybox } from "@fancyapps/ui";

export default class extends Controller {
  static values = {
    url: String
  }

  show(event) {
    event.preventDefault()

    return Fancybox.show(
      [{
        src: this.urlValue,
        type: 'ajax',
      }]
    )
  }
}
