/* global window */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  triggerDropdown () {
    this.element.blur();
    this.element.focus();
  }

  bookingGoogleCallback () {
    window.googleMapsIsLoaded = true;

    this.setupBooking();

    if (this.isInputElementFocused()) {
      setTimeout(this.triggerDropdown.bind(this), 100);
    }
  }

  connect () {
    window.bookingGoogleCallback = this.bookingGoogleCallback.bind(this);
  }

  isInputElementFocused () {
    return document.activeElement === this.element
  }

  setupBooking () {
    if (!window.googleMapsIsLoaded) {
      setTimeout(this.loadAutoComplete.bind(this), 100);
    } else {
      if (!this.autocomplete) {
        this.autocomplete = new google.maps.places.Autocomplete(this.element, this.autocompleteOptions());
      }
    }
  }

  autocompleteOptions () {
    var countryCode = this.element.dataset.countryCode;
    var language = this.element.dataset.language;
    var resultTypes = this.element.dataset.types;
    var types = [];

    if (resultTypes != "") {
      types = [resultTypes];
    }

    var options = {
      componentRestrictions: {
        country: countryCode
      },
      fields: [
        "address_components",
        "geometry"
      ],
      language: language,
      types: types
    }

    return options
  }

  loadAutoComplete () {
    if (!window.googleMapsIsLoaded) {
      const element = this.element;
      const { googlekey, region } = element.dataset;

      if (!document.getElementById('google-maps-sdk')) {
        var script = document.createElement('script');

        const urlOptions = `key=${googlekey}&libraries=places&callback=window.bookingGoogleCallback&region=${region}`

        script.id = 'google-maps-sdk';
        script.setAttribute('async', 'true');
        script.setAttribute('defer', 'true');
        script.src = `//maps.googleapis.com/maps/api/js?${urlOptions}`;

        document.body.append(script);
      }
    }
  }

  dontSubmit (input) {
    // dont submit the form when pressing enter in the autocomplete field
    google.maps.event.addDomListener(input, "keydown", function(e) {
      if (e.keyCode == 13) {
        e.preventDefault();
      }
    });
  }
}
