import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['code', 'outlet', 'size'];
  }

  connect() {
    this.maximumSize = parseInt(this.sizeTarget.getAttribute('max'), 10)
    this.minimumSize = parseInt(this.sizeTarget.getAttribute('min'), 10)
    this.sizeTarget.value = (this.maximumSize + this.minimumSize) / 2
    this.outletTarget.style = `height: ${this.maximumSize}px`
    this.updateSize();
  }

  getSize() {
    return this.sizeTarget.value
  }

  setMaxWidthOnImage(size) {
    let imgTags = this.outletTarget.getElementsByTagName('img')
    for (var i = imgTags.length - 1; i >= 0; i--) {
      let img = imgTags[i]
      img.style = `max-width: ${size}px`
    }
  }

  updateCode() {
    let embed = this.outletTarget
    this.codeTarget.value = embed.innerHTML.trim()
  }

  updateSize(event) {
    let size = this.getSize()
    this.setMaxWidthOnImage(size)
    this.updateCode()
  }
}
