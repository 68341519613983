import { Controller } from '@hotwired/stimulus';
import { Carousel } from '@fancyapps/ui';

export default class extends Controller {
  connect() {
    const container = this.element
    const options = {
      slidesPerPage: 1,
    }

    new Carousel(container, options)
  }
}
