import Sortable from 'stimulus-sortable'

export default class extends Sortable {
  static targets = ['photoCard']

  static values = {
    updateUrl: String
  }

  connect() {
    super.connect()
  }

  end() {
    const photoPositions = this.photoCardTargets.map((photoCard, index) => {
      let id = photoCard.dataset.photoId
      let position = index

      return {id: id, position: position}
    })

    this.persistPositions({photos: photoPositions})
  }

  get defaultOptions() {
    return {
      animation: 500,
      handle: '.move-handle'
    }
  }

  persistPositions(positions) {
    $.rails.ajax({
      url: this.updateUrlValue,
      type: 'PATCH',
      data: positions
    })
  }
}
