import ko from 'knockout';
import $ from 'jquery';

export default class ReplyViewModel {
  constructor() {
    this.section = ko.observable('');

    // Hide occupied sections submenus when entering the section
    this.section.subscribe(function hideSubSections(newSection) {
      if (newSection === 'occupied') {
        const targetId = '#reply-' + newSection;
        const target = $(targetId);
        $(target).find('.collapse').collapse('hide');
      }
    });
  }

  isSection(section) {
    return this.section() === section;
  }
}
