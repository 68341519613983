/* global window */
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    if (document.location.hash == "#share") {
      this.share();
    }
  }

  initializeFacebook() {
    if (typeof FB === 'undefined') {
      setTimeout(this.initializeFacebook.bind(this), 100);
    } else {
      if (window.facebookSdkInitialized) return;

      const { facebookAppId } = this.element.dataset;

      FB.init({
        appId            : facebookAppId,
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v11.0'
      });

      window.facebookSdkInitialized = true;
    }
  }

  share(event) {
    this.loadSdk();
    this.initializeFacebook();
    this.openShareWidget(this.element);

    if (event) {
      event.preventDefault();
    }
  }

  loadSdk() {
    if (!document.getElementById('facebook-sdk')) {
      var script = document.createElement('script');

      script.id = 'facebook-sdk';
      script.setAttribute('async', 'true');
      script.setAttribute('crossorigin', 'anonymous');
      script.setAttribute('defer', 'true');
      script.src = 'https://connect.facebook.net/en_US/sdk.js';

      document.body.append(script);
    }
  }

  openShareWidget(element) {
    if (typeof FB === 'undefined' || typeof window.facebookSdkInitialized === 'undefined') {
      setTimeout(this.openShareWidget.bind(this), 100, element);
    } else {
      const { reviewUrl } = this.element.dataset;

      FB.ui({
        method: 'share',
        href: reviewUrl,
      });
    }
  }
}
