import Sortable from 'stimulus-sortable'

export default class extends Sortable {
  static targets = ['movieCard']

  static values = {
    updateUrl: String
  }

  connect() {
    super.connect()
  }

  end() {
    const moviePositions = this.movieCardTargets.map((movieCard, index) => {
      let id = movieCard.dataset.movieId
      let position = index

      return {id: id, position: position}
    })

    this.persistPositions({movies: moviePositions})
  }

  get defaultOptions() {
    return {
      animation: 500,
      handle: '.move-handle'
    }
  }

  persistPositions(positions) {
    $.rails.ajax({
      url: this.updateUrlValue,
      type: 'PATCH',
      data: positions
    })
  }
}
