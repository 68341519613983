import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['inputField', 'openLink'];

  static values = {
    country: String,
    environment: String,
    ownerId: String,
  }

  apiKey () {
    return this.inputFieldTarget.dataset.apiKey;
  }

  autoSubmit () {
    return this.inputFieldTarget.dataset.autoSubmit;
  }

  cloudName () {
    return this.inputFieldTarget.dataset.cloudName;
  }

  connect () {
    this.setupCloudinaryScript();

    // Clear the input field value so it is ready for new input
    this.inputFieldTarget.value = this.inputFieldTarget.dataset.value || '';
  }

  customTranslations () {
    return this.inputFieldTarget.dataset.translations;
  }

  defaultUploadPreset () {
    return 'p5btpvca';
  }

  getContextMetadata () {
    return {
      country: this.countryValue,
      environment: this.environmentValue,
      owner_id: this.ownerIdValue,
    }
  }

  loadUploaderScript () {
    const script = document.createElement('script');
    script.src = 'https://upload-widget.cloudinary.com/global/all.js';

    this.openLinkTarget.appendChild(script);
    window.cloudinaryIsLoaded = true;

    // allow upload script some time to load and initialize
    setTimeout(this.setupCloudinaryScript.bind(this), 100);
  }

  multiple () {
    return this.inputFieldTarget.dataset.multiple || false;
  }

  setupCloudinaryScript() {
    if (!window.cloudinaryIsLoaded) {
      setTimeout(this.loadUploaderScript.bind(this), 100);
    } else {
      this.setupCloudinaryUploadWidget();
    }
  }

  translationsExists (translations) {
    return translations && !translations.includes("Translation missing")
  }

  setupTranslations (customTranslations) {
    if (this.translationsExists(customTranslations)) {
      const translations = JSON.parse(customTranslations);

      const parsedTranslations = {
        'en': {
          'or': translations.sources.local.drop_or,
          'local': {
            'browse': translations.sources.local.title,
            'dd_title_multi': translations.sources.local.drop_file,
            'dd_title_single': translations.sources.local.select_file,
            'drop_title_multiple': translations.sources.local.drop_file,
            'drop_title_single': translations.sources.local.select_file
          },
          'menu': {
            'files': translations.sources.local.title
          },
          'actions': {
            'upload': translations.progress.uploading
          },
          'crop': {
            'title': translations.crop.title,
            'crop_btn': translations.crop.crop_btn,
            'skip_btn': translations.crop.skip_btn,
            'reset_btn': translations.crop.reset_btn,
            'close_btn': translations.crop.close_btn,
            'close_prompt': translations.crop.close_prompt,
            'image_error': translations.crop.image_error,
            'corner_tooltip': translations.crop.corner_tooltip,
            'handle_tooltip': translations.crop.handle_tooltip
          },
          'queue': {
            'title': translations.queue.title,
            'title_uploading_with_counter': translations.queue.title_uploading_with_counter,
            'title_uploading': translations.queue.title_uploading,
            'mini_title': translations.queue.mini_title,
            'mini_title_uploading': translations.queue.title_uploading_with_counter,
            'retry_failed': translations.queue.retry_failed,
            'abort_all': translations.queue.abort_all,
            'done': translations.queue.done,
            'statuses': {
                'uploading': translations.queue.statuses.uploading,
                'error': translations.queue.statuses.error,
                'uploaded': translations.queue.statuses.uploaded,
                'aborted': translations.queue.statuses.aborted
            },
          },
        }
      };

      return parsedTranslations;
    } else {
      return {};
    }
  }

  setupCloudinaryUploadWidget () {
    const apiKey = this.apiKey();
    const autoSubmit = this.autoSubmit();
    const cloudName = this.cloudName();
    const contextMetadata = this.getContextMetadata();
    const customTranslations = this.customTranslations();
    const inputField = this.inputFieldTarget;
    const multiple = this.multiple();
    const linkTarget = this.openLinkTarget;
    const uploadPreset = this.defaultUploadPreset();

    const translations = this.setupTranslations(customTranslations)

    linkTarget.addEventListener('click', (event) => {
      cloudinary.openUploadWidget(
        {
          apiKey: apiKey,
          cloudName: cloudName,
          context: contextMetadata,
          multiple: multiple,
          showPoweredBy: false,
          sources: ["local"],
          text: translations,
          theme: 'white',
          uploadPreset: uploadPreset
        }, function(error, result) {
          if(error && error.message) {
          } else {
            if (result.info && result.info.files) {
              // Handle singular file upload
              if (!multiple) {
                let file = result.info.files[0];

                if (file.uploadInfo.path) {
                  inputField.value = file.uploadInfo.path;
                  if (autoSubmit == 'true') {
                    inputField.closest('form').submit();
                  }
                }
              } else {
                // Handle multiple file uploads, possibly
                const paths = []
                result.info.files.forEach((file) => {
                  if (file.uploadInfo.path) {
                    paths.push(file.uploadInfo.path)
                  }
                })
                inputField.value = paths.join(",")
                if (autoSubmit == 'true') {
                  inputField.closest('form').submit();
                }
              }
            }
          }
        }
      );
      return false;
    });
  }
}
