import NestedForm from 'stimulus-rails-nested-form'

export default class extends NestedForm {
  static targets = ['inputs']

  connect() {
    super.connect()
    this.intervalId = setInterval(this.addInputsIfNecessary.bind(this), 200)
  }

  addInputsIfNecessary (event) {
    const dummyEvent = { preventDefault: function () {} }
    if (this.needsMoreInputs()) {
      this.add(dummyEvent)
    }
  }

  disconnect () {
    super.disconnect()
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }

  needsMoreInputs () {
    return this.numberOfInputsTargetsWithoutUserInput() < 1
  }

  numberOfInputsTargetsWithoutUserInput () {
    return this.inputsTargets.filter(function (inputsTarget) {
      const fields = Array.from(inputsTarget.querySelectorAll('input:not([disabled]):not([type="hidden"]), textarea:not([disabled])'))
      return fields.every(function (input) {
        return input.value === ''
      })
    }).length
  }
}
