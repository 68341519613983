export default {
  create: function(element) {
    Player.element = $(element);
    Player.tracks = Player.element.find('.track');
    Player.controls = {
      pause: Player.element.find('.controls .pause'),
      play: Player.element.find('.controls .play'),
      stop: Player.element.find('.controls .stop'),
      skipBackward: Player.element.find('.controls .skip_backward'),
      skipForward: Player.element.find('.controls .skip_forward')
    };

    // Create and configure jPlayer
    Player.player = Player.element.find('.jPlayer');
    Player.player.jPlayer({
      swfPath: '/assets/'
    })
    .jPlayer("onProgressChange", function(lp,ppr,ppa,pt,tt) {
      var current = Player.current_track;

      var timestamp = current.find('.timestamp');
      timestamp.html($.jPlayer.convertTime(pt));

      var progress = current.next().find('div');

      progress.css('width', parseInt(ppa) + "%");
    })
    // Automatically play the next track when done.
    .jPlayer("onSoundComplete", function() {
      Player.skipForward();
    });

  },

  pause: function() {
    Player.player.jPlayer('pause');

    // Update UI
    Player.controls.play.show();
    Player.controls.pause.hide();
  },

  // Plays the track in the given element. If no element is given, continue playing the current track
  play: function(element) {
    if (element) {
      // Specific track wanted, find the track element
      element = $(element);
      if (element.hasClass('track')) {
        var track = element;
      } else {
        var track = $(element).parents('.track');
      }
    } else {
      if (!Player.currentTrack()) {
        // No track specified and no current track, play the first one
        var track = Player.tracks[0];
      }
    }

    // if playing and want to pause
    if(Player.isPlaying(track)) {
      track.removeClass('playing').addClass('pause');
      Player.pause();
      return;
    }

    // if previous paused and want to continue playing
     if(Player.isPaused(track)) {
      track.removeClass('pause').addClass('playing');
      Player.player.jPlayer('play');
      return;
    }

    // Change track?
    if (track) {
      Player.currentTrack(track);
    }

    // Start playback
    Player.player.jPlayer('play');

    // Update UI
    Player.controls.play.hide();
    Player.controls.pause.show();
  },

  isPlaying: function(track) {
    return (Player.isDoing(track, 'playing'));
  },

  isPaused: function(track) {
    return (Player.isDoing(track, 'pause'));
  },

  isDoing: function(track, class_name) {
    if (track) {
      return track.hasClass(class_name);
    } else {
      if ( !Player.current_track ) {
        return false;
      } else {
        return Player.current_track.hasClass(class_name);
      }
    }
  },

  playHead: function(element, event) {
    element = $(element);
    width = element.width();
    positionX = event.pageX - element.parent().offset().left;
    percent = (positionX / width) * 100;
    Player.player.jPlayer('playHead', percent);
  },

  skipBackward: function() {
    if (Player.currentTrack()) {
      var currentIndex = $.inArray(Player.currentTrack()[0], Player.tracks);
      if (currentIndex > -1) {
        var previousIndex = (Player.tracks.length + currentIndex - 1) % Player.tracks.length;
      }
    }

    if (previousIndex == undefined) {
      // Just play the last track
      previousIndex = Player.tracks.length - 1;
    }

    Player.play(Player.tracks[previousIndex]);
  },

  skipForward: function() {
    if (Player.currentTrack()) {
      var currentIndex = $.inArray(Player.currentTrack()[0], Player.tracks);
      if (currentIndex > -1) {
        var nextIndex = (currentIndex + 1) % Player.tracks.length;
      }
    }

    if (!nextIndex) {
      // Just play the first track
      nextIndex = 0;
    }

    Player.play(Player.tracks[nextIndex]);
  },

  stop: function() {
    Player.player.jPlayer('stop');

    // Update UI
    Player.controls.play.show();
    Player.controls.stop.hide();
  },


  currentTrack: function(track) {
    if (track) {
      // Set
      Player.current_track = $(track);

      var audio_url = Player.current_track.find('.audio_url');
      if (audio_url) {
        audio_url = audio_url.text().trim();
        Player.player.jPlayer('setFile', audio_url);
      }

      // Update UI
      Player.element.find('.current_track .name').html(Player.currentTrackName());

      Player.element.find('ol li.track').each(function() {
        $(this).removeClass('playing').removeClass('pause').addClass('stopped');
        $(this).find('.timestamp').html('00:00');
        $(this).next().hide();
      });
      Player.current_track.addClass('playing');
      Player.current_track.next().show();

    } else {
      // Get
      return Player.current_track;
    }
  },

  currentTrackName: function() {
    if (Player.current_track) {
      var name = $(Player.current_track).find('.name');
      return name.text().trim();
    };
  }
};
