/* global $ */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["dropdown", "from", "fixed", "priceRow", "priceFromRow"]

  connect () {
    this.toggleInputs();
  }

  toggleInputs () {
    const selectedValue = this.dropdownTarget.value;
    const ValidValues = ["fixed", "per serving", "per piece"]
    const fromValues = ["from", "from per serving", "from per piece"]

    if (ValidValues.includes(selectedValue)) {
      this.enableFixedInput();
    } else if (fromValues.includes(selectedValue)) {
      this.enableFromInput();
    } else {
      this.disableAndHideAllTargets();
    }
  }

  fixedTargetsArray () {
    return [this.fixedTarget, this.priceRowTarget]
  }

  fromTargetsArray () {
    return [this.fromTarget, this.priceFromRowTarget]
  }

  disableAndHideAllTargets () {
    this.hideAndDisable(this.fixedTargetsArray());
    this.hideAndDisable(this.fromTargetsArray());
  }

  enableFixedInput () {
    this.showAndEnable(this.fixedTargetsArray());
    this.hideAndDisable(this.fromTargetsArray());
  }

  enableFromInput () {
    this.showAndEnable(this.fromTargetsArray());
    this.hideAndDisable(this.fixedTargetsArray());
  }

  showAndEnable (targets) {
    targets[0].disabled = false;
    targets[1].classList.remove("d-none");
  }

  hideAndDisable (targets) {
    targets[0].disabled = true;
    targets[1].classList.add("d-none");
  }
}
