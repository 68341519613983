import { Controller } from '@hotwired/stimulus';
import FilesUpload from '../backend/files_upload';
import TextValidator from '../backend/text_validator';

export default class extends Controller {
  connect() {
    var filesUpload = new FilesUpload;
    filesUpload.apply();

    var textValidator = new TextValidator;
    textValidator.apply();
  }
}
