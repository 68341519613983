import { Controller } from '@hotwired/stimulus';
import intlTelInput from 'intl-tel-input';

export default class extends Controller {
  static targets = [
    "phoneNumber"
  ]

  static values = {
    defaultCountryCode: String,
  }

  connect() {
    var phoneNumberElement = this.phoneNumberTarget;

    var formElementName = phoneNumberElement.getAttribute("name")

    // Move the visible input element out of the submitted form data.
    // intlTelInput submits a hiddenInput with the proper name and value
    // instead.
    phoneNumberElement.name = "phone_number_input_element"

    this.input = intlTelInput(
      phoneNumberElement, {
        initialCountry: this.defaultCountryCodeValue,
        nationalMode: true,
        hiddenInput: formElementName,
      }
    )

    // Expose intlTelInput to the rest of the DOM, primarily for validations to
    // hook into.
    phoneNumberElement.intlTelInput = this.input;

    // Keep the hidden input up to date
    this.updateHiddenInput();
    phoneNumberElement.addEventListener("input", this.updateHiddenInput.bind(this));
  };

  updateHiddenInput() {
    const number = this.input.getNumber();
    this.input.hiddenInput.value = number;
  }
}
