export default function () {
  var that = this;

  this.apply = function () {
    var element = document.querySelector('[data-validate-text="true"]');

    this.disableSubmit(element.form);
    var intervalID = setInterval(this.disableSubmitIfBlank, 50, element);
    this.disableOnClick(element.form, intervalID);
  }

  this.disableSubmitIfBlank = function (element) {
    if (!element.value || element.value.trim() == "") {
      that.disableSubmit(element.form);
    } else {
      that.enableSubmit(element.form);
    }
  }

  this.submitElement = function (form) {
    return form.querySelector('input[type=submit]');
  }

  // Enabling and disabling code here seems to mess with :disable_with
  this.disableOnClick = function (form, intervalID) {
    var submit = that.submitElement(form);

    // let :disable_with do its thing!
    // (The page refresh after submit will restart the enabling / disabling code)
    submit.addEventListener("click", function() {
      clearInterval(intervalID);
    })
  }

  this.enableSubmit = function (form) {
    var submit = that.submitElement(form);
    submit.title = "";
    submit.disabled = false;
  }

  this.disableSubmit = function (form) {
    var submit = that.submitElement(form);
    submit.title = submit.dataset.submitTitle || "";
    submit.disabled = true;
  }
}
