import { Controller } from '@hotwired/stimulus'

// GoogleMapsController
//
// This controller is responsible for loading the Google Maps API and
// initializing the map.
export default class extends Controller {
  static targets = ['mapContainer']

  static values = {
    coordinates: Array,
    apiKey: String,
  }

  connect() {
    // Load the Google Maps API
    if (!window.initMap) {
      window.initMap = this.initializeMap.bind(this)
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKeyValue}&callback=initMap`;
      script.defer = true
      document.head.appendChild(script)
    } else {
      this.initializeMap()
    }
  }

  initializeMap() {
    const venueCoordinates = this.coordinatesValue

    if (venueCoordinates) {
      const venueLocation = new google.maps.LatLng(venueCoordinates[0], venueCoordinates[1])
      const mapOptions = {
        center: venueLocation,
        disableDefaultUI: true,
        zoom: 11,
      }

      const map = new google.maps.Map(this.mapContainerTarget, mapOptions)
      new google.maps.Marker({ position: venueLocation, map: map })
    }

  }
}
