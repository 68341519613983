import { Controller } from '@hotwired/stimulus';
import { easepick } from "@easepick/bundle";
import { LockPlugin } from "@easepick/lock-plugin";
import { DateTime } from "@easepick/datetime";

export default class extends Controller {
  static values = { locale: String }

  connect () {
    const today = new DateTime();

    new easepick.create({
      element: this.element,
      css: [
        "https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css",
      ],
      zIndex: 101,
      lang: this.localeValue,
      format: "DD-MM-YYYY",
      plugins: [LockPlugin],
      LockPlugin: {
        minDate: today
      }
    });
  }
}
