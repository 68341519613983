/* global $ */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    "submit",
  ]

  connect () {
    this.setupEventHandling()
  }

  setupEventHandling () {
    const form = this.element
    const submit = this.submitTarget

    $(form).
      on('ajax:beforeSend', function(event, xhr, settings) {
        $(submit).attr('disabled', true);
      }).
      on('ajax:success', function(event, data, status, xhr) {
        form.outerHTML = xhr.responseText;

        if (typeof(ga) != 'undefined') {
          ga(['send', 'pageview', '/reviews/create']);
        }
      }).
      on('ajax:error', function(event, xhr, status, error) {
        // console.log("error in form from jquery handling")
        form.outerHTML = xhr.responseText;
      }).
      on('ajax:complete', function(event, xhr, status) {
        $(submit).removeAttr('disabled');
      });
  }
}
