import { Controller } from '@hotwired/stimulus';
import IMask from "imask"

// Turns an input field into a masked input field that accepts amounts, ie
// monetary values.
export default class extends Controller {
  static values = {
    // Character that delimits the decimal places in the input field.
    decimalSeparator: String,

    // Character that delimits the thousands in the input field.
    thousandsSeparator: String,

    // The number of decimal places to allow in the input field.
    precision: Number,
  }

  buildMask(element, options) {
    return IMask(
      element,
      options
    )
  }

  configureMaskedInput (element, submittableInput) {
    // Ensure the presentation of the input is not submitted
    element.name = ""

    // IMask for some reason doesn't support number fields. Force the input to
    // be a text field so IMask works correctly.
    element.type = "text"

    // Add the submittable, hidden, input after the original input in the DOM
    element.after(submittableInput)

    this.mask = this.buildMask(element, this.maskOptions())
    // Initialize the mask on the show input
    // Ensure the mask is initialized with the correct value
    this.mask.unmaskedValue = submittableInput.value

    return element
  }

  connect() {
    const submittableInput = this.createSubmittableInput(this.element)
    const maskedInput = this.configureMaskedInput(this.element, submittableInput)

    this.updateSubmittableInputValueOnMaskAccept(submittableInput)
  }

  createSubmittableInput (element) {
    const submittableInput = document.createElement("input")
    submittableInput.type = "hidden"
    submittableInput.name = element.name
    submittableInput.value = element.value

    return submittableInput
  }

  disconnect () {
    this.mask.destroy()
  }

  // Returns the options to pass to IMask.
  maskOptions () {
    return {
      mask: Number,
      mapToRadix: ['.'],  // symbols to process as radix
      radix: this.decimalSeparatorValue,
      scale: this.precisionValue,
      thousandsSeparator: this.thousandsSeparatorValue,
    }
  }

  // Ensure that we assign the correct value to the hidden field
  // when the user inputs any (number) value
  updateSubmittableInputValueOnMaskAccept (submittableInput) {
    this.mask.on('accept', function() {
      // Assign the correct value to the hidden field
      submittableInput.value = this.mask.unmaskedValue
    }.bind(this));
  }
}
