import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    $(this.stateChangeForms()).on('ajax:success', (event, html) => {
      this.element.innerHTML = html;
    })
  }

  stateChangeForms() {
    return this.element.querySelectorAll('form.button_to')
  }
}
