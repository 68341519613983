import { Controller } from '@hotwired/stimulus';
import ko from 'knockout';
import FilesUpload from '../backend/files_upload';
import ReplyViewModel from '../models/reply';

export default class extends Controller {
  connect() {
    var filesUpload = new FilesUpload;
    filesUpload.apply();

    const reply = new ReplyViewModel();
    ko.applyBindings(reply, this.element);
  }
}
