import { Controller } from '@hotwired/stimulus';

// Sets the visibility of elements based on the checked state of a source
// element.
//
// source target is expected to emit a change event when the value changes so
// the UI can be updated.
//
// Target elements must be configured with a `data-visible-when` attribute set
// to either "on" or "off" depending on when the element should be visible. The
// element will have `d-none` added and removed from their classlist as
// applicable. Target elements are also be disabled when hidden.
export default class extends Controller {
  static targets = ['source', 'target']

  connect() {
    super.connect()

    // Set initial visibility
    this.setVisibility()

    this.sourceTarget.addEventListener('change', this.handleChange.bind(this))
  }

  currentlyChecked() {
    return this.sourceTarget.checked
  }

  currentState() {
    if (this.sourceTarget.checked) {
      return "on"
    } else {
      return "off"
    }
  }

  disconnect() {
    this.sourceTarget.removeEventListener('change', this.handleChange.bind(this))

    super.disconnect()
  }

  handleChange() {
    this.setVisibility()
  }

  hiddenClass() {
    return 'd-none'
  }

  setVisibility() {
    this.targetTargets.forEach(element => {
      this.setVisibilityOfElement(element)
    })
  }

  setVisibilityOfElement(element) {
    if (!element.dataset.visibleWhen) return

    if (element.dataset.visibleWhen === this.currentState()) {
      element.classList.remove(this.hiddenClass())
      element.disabled = false
    } else {
      element.classList.add(this.hiddenClass())
      element.disabled = true
    }
  }
}
