/* global $ */

export default function () {
  var that = this;
  var inputElement = document.querySelector('[data-files-input="true"]');
  var outputElement = document.querySelector('[data-files-output="true"]');

  this.addNewInputGroup = function (originalNode) {
    var newInputNode = originalNode.cloneNode();

    // Make sure we have no files pre-selected. Some browsers preserve the
    // filelist from the original node we've cloned from, and we don't want that
    newInputNode.value = "";

    // Make the new input element create more input elements when changed
    newInputNode.addEventListener("change", that.onFileSelected);

    inputElement.appendChild(newInputNode);
    return newInputNode;
  }

  this.moveFileInputToSelectedFiles = function (inputNode) {
    // Remove attributes necessary for a visible input field
    inputNode.removeAttribute("id");
    inputNode.removeEventListener("change", that.onFileSelected);

    // Create a delete icon element for the input node
    var deleteIcon = document.createElement("span");
    deleteIcon.classList.add("message-remove-icon");

    // Create label node (the visible part of the new input element)
    var labelNode = document.createElement("label");
    labelNode.classList.add("message-tag")
    var textElement = document.createTextNode(inputNode.files[0].name);

    // Add the text and icon to the label node
    labelNode.appendChild(textElement);
    labelNode.appendChild(deleteIcon);

    // Create parent node for input
    var formGroupNode = document.createElement("div");
    formGroupNode.classList.add("form-group", "message-file");

    // Add the custom input and label element to the parent node
    formGroupNode.appendChild(inputNode);
    formGroupNode.appendChild(labelNode);

    // Remove the file by clicking the new custom element
    formGroupNode.addEventListener("click", function (event) {
      outputElement.removeChild(formGroupNode);
    });

    // Add our new custom input node to the output element
    outputElement.appendChild(formGroupNode);
  }

  this.onFileSelected = function (event) {
    var originalFileInputElement = event.target;

    // Create a new input node, looking like the original
    that.addNewInputGroup(originalFileInputElement);

    // Move the original file input to the list of inputs to submit and style it
    // like a tag/selected file.
    that.moveFileInputToSelectedFiles(originalFileInputElement);
  };

  this.apply = function () {
    inputElement.
      querySelector("input[type='file']").
      addEventListener("change", this.onFileSelected);
  }
};
