/* global $ */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["toggler"];

  toggle() {
    $(this.togglerTargets).toggleClass("btn-primary");
  }
}
