import Sortable from 'stimulus-sortable'

export default class extends Sortable {
  static targets = ['audioCard']

  static values = {
    updateUrl: String
  }

  connect() {
    super.connect()
  }

  end() {
    const audioPositions = this.audioCardTargets.map((audioCard, index) => {
      let id = audioCard.dataset.audioId
      let position = index

      return {id: id, position: position}
    })

    this.persistPositions({clips: audioPositions})
  }

  get defaultOptions() {
    return {
      animation: 500,
      handle: '.move-handle'
    }
  }

  persistPositions(positions) {
    $.rails.ajax({
      url: this.updateUrlValue,
      type: 'PATCH',
      data: positions
    })
  }
}
