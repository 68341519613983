import { Controller } from '@hotwired/stimulus';

// Toggles the checked state of elements on and off
export default class extends Controller {
  static targets = ["checkbox"]

  // Check all checkboxes
  check(event) {
    this.setChecked(true, event.params.tag)

    event.preventDefault();
  }

  setChecked(checked, onlyWithTag) {
    this.checkboxTargets.forEach((checkbox) => {
      const rawTaggedValue = checkbox.dataset.bulkCheckerTaggedValue
      let taggedValues = []
      if (rawTaggedValue) {
        taggedValues = JSON.parse(rawTaggedValue)
      }
      if (!onlyWithTag || taggedValues.includes(onlyWithTag)) {
        checkbox.checked = checked
      }
    })
  }

  toggle(event) {
    const allChecked = this.checkboxTargets.every((checkbox) => checkbox.checked)
    this.setChecked(!allChecked, event.params.tag)

    event.preventDefault();
  }

  // Uncheck all checkboxes
  uncheck(event) {
    this.setChecked(false, event.params.tag)

    event.preventDefault();
  }
}
