import { Controller } from '@hotwired/stimulus';
import { Fancybox } from '@fancyapps/ui';

export default class extends Controller {
  static targets = ['link']

  show (event) {
    event.preventDefault()
    const sources = this.buildSources(this.linkTargets)

    const options = {
      mainClass: 'photo-gallery',
      Html: {
        video: {
          autoplay: false,
        },
      },
      startIndex: event.params.index,
    }

    return Fancybox.show(sources, options)
  }

  buildSources (links) {
    const sources = []

    links.forEach((link) => {
      sources.push({ src: link.href, thumb: link.dataset.thumb, type: 'image' })
    })


    return sources
  }
}
