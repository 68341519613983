import { Controller } from '@hotwired/stimulus';

// Heavily inspired by
// https://github.com/stimulus-components/stimulus-rails-nested-form/blob/master/src/index.ts
export default class extends Controller {
  static targets = [
    // Each individual photo available to be added to the form
    'availablePhoto',

    // Each individual photo added to the form
    'selectedPhoto',

    'target',
    'template'
  ]

  // Removes a selected photo from the form
  //
  deselectPhoto(event) {
    const selectedPhotoTarget = event.
      target.
      closest('[data-photos-form-target="selectedPhoto"]')

    // If the photo is new, ie it has been selected in this session, just remove
    // the element entirely.
    //
    // Otherwise, hide the photo and mark it for deletion.
    if (selectedPhotoTarget.dataset.newRecord === "true") {
      selectedPhotoTarget.remove()
    } else {
      selectedPhotoTarget.classList.add('d-none')
    }

    // Mark the photo for deletion
    const input = selectedPhotoTarget.querySelector("input[name*='_destroy']")
    if (input) {
      input.value = '1'
    }

    // Make the photo available again
    const photoId = event.params.photoId.toString()
    const availablePhoto = this.availablePhotoTargets.find((photo) => {
      return photo.dataset.photoId === photoId
    })
    if (availablePhoto) {
      availablePhoto.classList.remove('d-none')
    }
  }

  // Adds a new photo to the form
  //
  // The added photo will be a copy of the template, with the photoId and
  // photoUrl replaced with the relevant parameters from this action (see
  // https://stimulus.hotwired.dev/reference/actions#action-parameters).
  selectPhoto(event) {
    const photoId = event.params.photoId.toString()
    const photoUrl = event.params.photoUrl

    const existingSelectedPhotoTarget = this.selectedPhotoTargets.find((photo) => {
      return photo.dataset.photoId === photoId
    })

    // This is a photo that was initially selected and then deselected, so we
    // need to remove the existing element, including its _destroy-field
    if (existingSelectedPhotoTarget) {
      existingSelectedPhotoTarget.remove()
    }

    let content = this.templateTarget.innerHTML
    content = content.replace(/NEW_RECORD/g, new Date().getTime().toString())
    content = content.replace(/PHOTO_ID/g, photoId)
    content = content.replace(/PHOTO_URL/g, photoUrl)
    this.targetTarget.insertAdjacentHTML('beforebegin', content)

    const availablePhotoTarget = event.
      target.
      closest('[data-photos-form-target="availablePhoto"]')
    availablePhotoTarget.classList.add("d-none")
  }
}
