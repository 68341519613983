import { Controller } from '@hotwired/stimulus';
import intlTelInput from 'intl-tel-input';
// Ensure utils are loaded so we can show phone placeholders before
// countrychange events
import 'intl-tel-input/build/js/utils';

export default class extends Controller {
  static targets = [
    "dialCodePicker",
    "phoneNumber"
  ]

  static values = {
    defaultCountryCode: String,
  }

  connect() {
    var dialCodePickerElement = this.dialCodePickerTarget;
    var phoneNumberElement = this.phoneNumberTarget;

    var input = intlTelInput(
      dialCodePickerElement, {
        // we'll manage placeholders manually as the autoPlaceholder inserts
        // the placeholder in the input (dial_code) field
        autoPlaceholder: "off",
        initialCountry: this.defaultCountryCodeValue
      }
    )

    var country = input.getSelectedCountryData();

    // ensure correct country code is inserted on field
    // if user for some reason changes the field with other types of input
    dialCodePickerElement.value = `+${country.dialCode}`;

    this.setPlaceHolder(phoneNumberElement, country);
    this.countryChangeListener(dialCodePickerElement, phoneNumberElement, input);
  };

  countryChangeListener(dialCodePickerElement, targetElement, input) {
    var that = this;

    dialCodePickerElement.addEventListener("countrychange", function() {
      var country = input.getSelectedCountryData();
      that.setPlaceHolder(targetElement, country);
    });
  };

  setPlaceHolder(targetElement, country) {
    if (window.intlTelInputUtils) {
      var placeHolderText = window.intlTelInputUtils.getExampleNumber(
        country.iso2,
        true,
        window.intlTelInputUtils.numberType.FIXED_LINE
      );

      // Set an example number as placeholder for phone input
      targetElement.setAttribute("placeholder", placeHolderText);
    }
  };
}
