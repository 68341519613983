import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['childCategory', 'emptyChildCategory', 'rootCategory']

  connect () {
    this.updateChildCategories();
  }

  updateChildCategories () {
    const childCategories = this.childCategoryTargets
    const venueCategorySelect = this.rootCategoryTarget
    const selectedOption = venueCategorySelect.options[venueCategorySelect.selectedIndex].value

    childCategories.forEach((childCategory) => {
      const parentCategoryId = childCategory.dataset.parentCategoryId

      if (parentCategoryId === selectedOption) {
        this.emptyChildCategoryTarget.style.display = 'none'
        this.showCategory(childCategory)
      } else if (selectedOption === '') {
        this.emptyChildCategoryTarget.style.display = 'block'
        this.hideCategory(childCategory)
      } else {
        this.hideCategory(childCategory)
      }
    })
  }

  hideCategory (childCategory) {
    childCategory.style.display = 'none'
    childCategory.disabled = true
  }

  showCategory (childCategory) {
    childCategory.classList.remove('d-none')
    childCategory.style.display = 'block'
    childCategory.disabled = false
  }
}
